import { PHONE_NUMBER_PREFIX } from '../constants/common';
import PasswordValidator from 'password-validator';
import { Subject } from 'rxjs';
import i18next from 'i18next';
import moment from 'moment';
declare global {
	interface Window {
		runConfig: any,
		reloadHeader: any;
	}
}
//
export const BASE_URL = window.runConfig.BaseURL;
export const UserDataKey = window.runConfig.Keys.UserData;
export const GOOGLE_API_KEY = window.runConfig.Keys.GoogleApiKey;
export const WEBBASEURL = window.runConfig.WebUrl;
export const SHAREDCOOKIE = window.runConfig.sharedCookieDomain || 'npit.at';
export const MODE = window.runConfig.mode || 'T1d';
const defaultUspsTrackingUrl = 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=';
export const USPS_TRACKING_URL = window.runConfig.uspsTrackingUrl || defaultUspsTrackingUrl;

export const GoogleAutocompleteActive = window.runConfig.GoogleAutocompleteActive || false;

export const {RE_CAPTCHA_SITE_KEY = '', RE_CAPTCHA_SECRET_KEY = '', } = window.runConfig || {};

//

export const $enableAddressValidation = new Subject<boolean>();

export const updateEnableAddressValidation = (val: string | boolean) => {
	let isTrue = false;
	if (typeof val === 'string') {
		isTrue = val === 'true' ? true : false;
	} else if (typeof val === 'boolean') {
		isTrue = val;
	}
	$enableAddressValidation.next(isTrue);
	return isTrue;
};

export const checkIsEnableAddressValidation = () => {
	return updateEnableAddressValidation(valueFromUserCookie('EnableAddressValidation') || false);
};

export const $showNotification = new Subject();
export const $dismissNotification = new Subject();
export const $editNotification = new Subject();

type notificationSystemLevel = 'success' | 'error' | 'warning' | 'info';
export const showNotification = (
	level: notificationSystemLevel,
	message: string,
	uid?: string
) => {
	$showNotification.next({ level, message, uid });
};

export const editNotification = (uid: string, message: string) => {
	$editNotification.next({ uid, message });
};

export const dismissNotification = (uid: string) => {
	$dismissNotification.next(uid);
};


export const IsLoggedIn = () => {
	// return ReadLocalStorage(UserDataKey) !== undefined;
	return getCookie(UserDataKey);
};

export const valueFromUserCookie = (key: string) => {
	const UserData: any = getCookie(UserDataKey);
	return UserData ? UserData[key] : '';
};

export const isAdmin = () => {
	return valueFromUserCookie('RoleName') === 'Admin';
};

export const isUser = () => {
	return ['User', 'Other', 'Child', 'Patient'].includes(valueFromUserCookie('RoleName'));
};

export const isClinician = () => {
	return valueFromUserCookie('RoleName') === 'Clinician';
};

export const isEnterprise = () => {
	return valueFromUserCookie('RoleName') === 'Enterprise';
};

export const disabledSideMenu = () : boolean => {
	return false;
	return  (isClinician() && !valueFromUserCookie('IsProfileCompleted')) ? true : false;
};

export const disabledHeader = (): boolean => {
	return false;
	return (isClinician() && !valueFromUserCookie('IsProfileCompleted')) ? true : false;
};

export const ValueFromUserData = (key: string) => {
	let UserData: any = ReadLocalStorage(UserDataKey);

	if (UserData !== undefined && UserData !== null) {
		UserData = JSON.parse(UserData);
		// console.log(UserData, key)
		return UserData[key];
	} else {
		return '';
	}
};

export const ReduceString = (text: string) => {
	return text
		.trim()
		.replace(/ /g, '')
		.toLowerCase();
};

export const SetLocalStorage = (key: string, value: string) => {
	localStorage.setItem(key, value);
};

export const ReadLocalStorage = (key: string) => {
	const result = localStorage.getItem(key);
	if (result === undefined || result === null) {
		return undefined;
	} else {
		return result;
	}
};

export const RemoveFromLocalStorage = (key: string) => [
	localStorage.removeItem(key)
];

// chnage the language
export const changeLanguage = (code: string) => {
	SetLocalStorage('lang', code);
	i18next.changeLanguage(code);
};

export const StandardDateFormat = (Date: any) => {
	return moment(Date).format('MMM DD, YYYY');
};

export const FormatCurrency = (value: string | number) => {
	if (value === undefined || value === null) return '$0';
	return `$${value}`;
};

export const FilterRecords = async (
	Records: any[] = [],
	Keys: string[] = [],
	SearchString = ''
): Promise<any[]> => {
	const FilteredArray: any = [];
	return new Promise<any[]>(resolve => {
		Records.forEach((val: any, idx: number) => {
			for (let iterator = 0; iterator < Keys.length; iterator++) {
				if (
					val[Keys[iterator]] !== null &&
					val[Keys[iterator]] !== undefined &&
					ReduceString(val[Keys[iterator]].toString()).includes(
						// eslint-disable-next-line no-mixed-spaces-and-tabs
						ReduceString(SearchString)
					)
				) {
					FilteredArray.push(val);
					break;
				}
			}
			//   return obj.VAL.indexOf(value) !=== -1;

			if (Records.length === idx + 1) {
				resolve(FilteredArray);
			}
		});
	});
};

export const SummarizeText = (text: string, count: number) => {
	if (text === undefined || text === null) {
		return '';
	}

	if (text.length <= count) {
		return text;
	}

	return text.substr(0, count) + '...';
};

export const fullDateFormat = (date: string) => {
	return moment(date).format('MMMM DD YYYY, h:mm a');
};

export const getFormatDate = (date: string | Date, format: string) => {
	return moment(date).format(format);
};

export const getMonthDateYear = (date: string) => {
	return moment(date).format('MMMM DD, YYYY');
};

export const downloadFile = (dataurl: string, filename: string) => {
	const a = document.createElement('a');
	a.href = dataurl;
	a.setAttribute('download', filename);
	a.click();
};


export const readFileAsDataUrl = (file: any): Promise<string> => {
	return new Promise(res => {
		const reader = new FileReader();
		reader.onload = () => {
			res(reader.result as string);
		};
		reader.readAsDataURL(file);
	});
};

export const disableScrollOnBody = (canDisable: boolean) => {
	const overflow = canDisable ? 'hidden' : 'auto';
	document.getElementsByTagName('body')[0].style.overflow = overflow;
};

export const createCookie = (
	name: string,
	value: string,
	domain = 'npit.at',
	days = 7
) => {
	// 1 week valid
	const d = new Date();
	d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + d.toUTCString();
	document.cookie =
        name + '=' + value + ';' + expires + ';domain=' + domain + ';path=/;secure';
};

export const updateCookie = (cname: string, cvalue: { [x: string]: string | number; }, domain = 'npit.at', exdays = 7) => {
	let oldValue = {};
	const oldVal = getCookie(cname);
	if (oldVal) {
		oldValue = oldVal;
	}
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + d.toUTCString();
	const value = JSON.stringify({ ...oldValue, ...cvalue });
	document.cookie = cname + '=' + value + ';' + expires + ';domain=' + domain + ';path=/;secure';
};

export const getCookie = (name: string) => {
	const cookie = document.cookie;
	const value =
		cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
	return value ? JSON.parse(value) : '';
};

export const clearCookies = () => {
	const cookies = document.cookie.split('; ');
	document.cookie.split(';').forEach(function (c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=Thu, 01-Jan-1970 00:00:01 GMT;path=/'); });
	for (let c = 0; c < cookies.length; c++) {
		const d = window.location.hostname.split('.');
		while (d.length > 0) {
			const cookieBase = encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=/';
			const p = location.pathname.split('/');
			document.cookie = cookieBase + '/';
			while (p.length > 0) {
				document.cookie = cookieBase + p.join('/');
				p.pop();
			}
			d.shift();
		}
	}
};


export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
	'July', 'August', 'September', 'October', 'November', 'December'
];
export const getLocaleMonthDateYear = (dateString: string) => {
	const gmtDateTime = moment.utc(dateString, '');
	const local = gmtDateTime.local().format('MMMM DD, YYYY');
	return local;

};

export const getLocaleTime = (dateString: string) => {
	const gmtDateTime = moment.utc(dateString, '');
	const local = gmtDateTime.local().format('hh:mm A');
	return local;
};

export const getSearchQuery = (query: string, name: string) => {
	const urlSearcher = new URLSearchParams(query);
	return urlSearcher.get(name);
};

export const getYears = (date: string) => moment().diff(date, 'years', false);

export const passwordSchema = () => {
	const schema = new PasswordValidator();
	return schema
		.is().min(8)                                    // Minimum length 8
		.has().uppercase(1)                              // Must have uppercase letters
		.has().lowercase(1)                              // Must have lowercase letters
		.has().digits(1)                                // Must have at least 2 digits
		.has().symbols(1)
		.has().not().spaces()
		.has().not('<')
		.has().not('>');
};
export const removePhoneNumberPrefix = (phoneNumber: string) => phoneNumber.replace(PHONE_NUMBER_PREFIX, '');

export const addPhoneNumberPrefix = (phoneNumber: string) => PHONE_NUMBER_PREFIX.concat(removePhoneNumberPrefix(phoneNumber));

export const isValidDate = (date: any) => moment(date).isValid();

export const truncateString = (text: string, size: number) => text.length > size ? text.slice(0, size - 1) + '…' : text;

export const orderNow = () => {
	const { TotalOrderCount = 0 } = getCookie(UserDataKey);
	(window.location.href = `${WEBBASEURL}/order-form${TotalOrderCount ? '-2' : ''}`);
};

export const getPrePath = () => {
	let prePath = '';
	if (isEnterprise()) {
		prePath = '/enterprise';
	} else if (isClinician()) {
		prePath = '/clinician';
	} else {
		prePath = '/dashboard';
	}
	return prePath;
};

export const queryString = (obj: {[x:string]: any}) => Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');

export const openUspsTracking = (trackingNumber: string | number) => {
	window.open(USPS_TRACKING_URL + trackingNumber, '_blank');
};

export const resolveDate = (datestring: any) => {
	if(datestring && typeof datestring === 'string'){
		const timestamp = new Date(datestring).getTime();
		if(timestamp >= 0){
			return datestring;
		}
	}
	return ''; 
};

export const delay = (ms:number) => new Promise(res => setTimeout(res, ms));