
// SASS Import
import './App.scss';
import './index.scss';

import React, { createRef, useEffect } from 'react';
import NotificationSystem from 'react-notification-system';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { $dismissNotification, $editNotification, $showNotification, MODE } from './common/utility';

// import enLang from './translations/en.json';
// import arrToObjectLang from './translations/csvjson.json';

const EnterpriseLayout = React.lazy(() => import('./containers/EnterpriseLayout'));
const ClinicianLayout = React.lazy(() => import('./containers/ClinicianLayout'));
const AdminLayout = React.lazy(() => import('./containers/AdminLayout'));
const AdminLogin = React.lazy(() => import('./views/Login'));
const UserVerification = React.lazy(() => import('./views/ValidateEmail'));



const theme = createTheme({
	palette: {
		primary: {
			main: '#E43638',
		},
		secondary: {
			main: '#2B4C6F'
		}
	},
	typography: {
		fontFamily: '"Rubik", sans-serif',
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
		    @font-face {
		      font-family: '"Rubik", sans-serif';
		    }
		  `,
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontFamily: '"Rubik", sans-serif'
				}
			},
		},
		MuiButtonBase: {
			defaultProps: {
				color: 'primary'
			},
			styleOverrides: {
				root: {
					color: 'E43638'
				}
			}
		}
	}
});
const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);


function App() {
	const notificationSystem = createRef<any>();
	useEffect(() => {
		$showNotification.subscribe((val: any) => {
			const notification = notificationSystem.current;
			if (notification) {
				notification.addNotification({
					...val,
					message: <div dangerouslySetInnerHTML={{__html: val.message}}></div>,
					position: 'tr',
					autoDismiss: val.uid ? 0 : 4,
					dismissible: val.uid ? 'none' : 'both',
				});
			}
		});
		$dismissNotification.subscribe((val: any) => {
			const notification = notificationSystem.current;
			if (notification) {
				notification.removeNotification(val);
			}
		});
		$editNotification.subscribe((val: any) => {
			const notification = notificationSystem.current;
			if (notification) {
				notification.editNotification(val.uid, {
					message: val.message,
				});
			}
		});


		// console.log('enLang', enLang);
		// const tempEnLang:any = enLang;
		// const tempArray:any = [];
		// Object.keys(tempEnLang).map(function(key) {
		// 	tempArray.push({
		// 		key: key,
		// 		english: tempEnLang[key]
		// 	});
		//   });
		//   console.log('tempArray', tempArray);
		// console.log(arrToObjectLang.length);
		// const tempSpLang: any = {};
		// arrToObjectLang.map(item => {
		// 	tempSpLang[item.key] = item.spanish;
		// });
		// console.log('tempSpLang', tempSpLang);

	}, []);

	return (
		<>
			<Helmet>
				{MODE === 'Covid' && <title>COVID Neutralizing Antibody Admin</title>}
				{MODE === 'T1d' && <title>Type 1 Testing - Enable Biosciences</title>}
			</Helmet>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<div className="App">
					<HashRouter>
						<React.Suspense fallback={loading}>
							<Switch>
								<Route
									path="/dashboard"
									exact={false}
									// @ts-ignore
									render={(props) => <AdminLayout {...props} />}
								/>
								<Route
									path="/clinician"
									exact={false}
									// @ts-ignore
									render={(props) => <ClinicianLayout {...props} />}
								/>
								<Route
									path="/enterprise"
									exact={false}
									// @ts-ignore
									render={(props) => <EnterpriseLayout {...props} />}
								/>
								<Route
									path="/login"
									exact={true}
									// @ts-ignore
									render={(props) => <AdminLogin {...props} />}
								/>
								<Route
									path="/validateEmail"
									exact={true}
									// @ts-ignore
									render={(props) => <UserVerification {...props} />}
								/>
								<Redirect from='/' to='/login' />
							</Switch>
						</React.Suspense>
					</HashRouter>
				</div>
			</ThemeProvider>
			<NotificationSystem ref={notificationSystem} />
		</>
	);
}
export default App;
