export const COUNTRYID = 231;

export const PATIENTROLEID = 4;

export const CLINICIANROLEID = 3;

export const ENTERPRISEROLEID = 5;

export const PHONE_NUMBER_PREFIX = '+1';

export const buttonStyles = { padding: '6px 16px', color: '#fff' };

export const orderFilterFields = [
	{
		elementType: 'Input',
		label: 'Order Id',
		placeholder: '',
		name: 'orderId',
		type: 'text',
		defaultValue: '',
	},
	{
		elementType: 'Select',
		label: 'Order Status',
		placeholder: 'Select Status',
		name: 'orderStatusDetails',
		type: 'text',
		defaultValue: '',
		options: [
			{
				value: '',
				label: 'All',
			},
			{
				value: 'Pending',
				label: 'Pending',
			},
			{
				value: 'Order Received',
				label: 'Order Received',
			},
			{
				value: 'Order Kit in Transit',
				label: 'Order Kit in Transit',
			},
			{
				value: 'Test Kit Registered',
				label: 'Test Kit Registered',
			},
			{
				value: 'Sample in Transit to Lab',
				label: 'Sample in Transit to Lab',
			},
			{
				value: 'Sample Received',
				label: 'Sample Received',
			},
			{
				value: 'Result',
				label: 'Result',
			},
		],
	},
	{
		elementType: 'Input',
		label: 'Clinician Approved',
		placeholder: '',
		name: 'clinicianApproved',
		type: 'text',
		defaultValue: '',
	},
	{
		elementType: 'Input',
		label: 'Order Date',
		placeholder: '',
		name: 'orderDate',
		type: 'date',
		defaultValue: '',
	},
	{
		elementType: 'Input',
		label: 'Patien tName',
		placeholder: '',
		name: 'patientName',
		type: 'text',
		defaultValue: '',
	},
	{
		elementType: 'Input',
		label: 'Customer Name',
		placeholder: '',
		name: 'customerName',
		type: 'text',
		defaultValue: '',
	},
	{
		elementType: 'Input',
		label: 'DOB',
		placeholder: '',
		name: 'dob',
		type: 'date',
		defaultValue: '',
	},
	{
		elementType: 'Input',
		label: 'Age',
		placeholder: '',
		name: 'age',
		type: 'text',
		defaultValue: '',
	},
	// {
	// 	elementType: 'Input',
	// 	label: 'ethnicityId',
	// 	name: 'ethnicityId',
	// 	type: 'text',
	// 	defaultValue: '',
	// },
	{
		elementType: 'Input',
		label: 'Gender',
		placeholder: '',
		name: 'gender',
		type: 'text',
		defaultValue: '',
	},
	{
		elementType: 'Input',
		label: 'City',
		placeholder: '',
		name: 'city',
		type: 'text',
		defaultValue: '',
	},
	{
		elementType: 'Input',
		label: 'State',
		placeholder: '',
		name: 'state',
		type: 'text',
		defaultValue: '',
	},
	{
		elementType: 'Input',
		label: 'Zip Code',
		placeholder: '',
		name: 'zipCode',
		type: 'text',
		defaultValue: '',
	},
	{
		elementType: 'Input',
		label: 'InBound Tracking No',
		placeholder: '',
		name: 'inBoundTrackingNo',
		type: 'text',
		defaultValue: '',
	},
	{
		elementType: 'Input',
		label: 'OutBound Tracking No',
		placeholder: '',
		name: 'outBoundTrackingNo',
		type: 'text',
		defaultValue: '',
	},
	{
		elementType: 'Checkbox',
		label: 'Seen Result',
		placeholder: '',
		name: 'seenResult',
		type: 'checkbox',
		defaultValue: false,
	},
	{
		elementType: 'Checkbox',
		label: 'My Orders',
		placeholder: '',
		name: 'myOrders',
		type: 'checkbox',
		defaultValue: false,
	},
	{
		elementType: 'Checkbox',
		label: 'Confirmed Order',
		placeholder: '',
		name: 'IsOrderConfirmed',
		type: 'checkbox',
		defaultValue: false,
	},
	{
		elementType: 'Checkbox',
		label: 'CSV',
		placeholder: '',
		name: 'isExport',
		type: 'checkbox',
		defaultValue: false,
	},
];

export const RIGHT_SIDE_MENU = [{
	label: '',
	link: '',
	actionName: '',
	isAdmin: true,
	isUser: true,
	isClinician: false,
	isEnterprise: false,
}];
