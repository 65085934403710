import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ReadLocalStorage, RE_CAPTCHA_SITE_KEY } from './common/utility';
import en from './translations/en.json';
import es from './translations/es.json';


i18next.init({
	fallbackLng: 'en',
	debug: true,
	interpolation: { escapeValue: false },  // React already does escaping
	lng: ReadLocalStorage('lang') || 'en',
	resources: {
		en: {
			common: en             // 'common' is our custom namespace
		},
		es: {
			common: es
		}
	},
});

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18next}>
			<GoogleReCaptchaProvider reCaptchaKey={RE_CAPTCHA_SITE_KEY}>
    			<App />
			</GoogleReCaptchaProvider>
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
